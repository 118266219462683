import Link from 'next/link'

// Bootstrap
import {
	Col,
	Row,
	Image,
	Container
} from 'react-bootstrap'

// Style
import styles from '../../styles/App.module.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// Other
import { useTranslation } from 'react-i18next'

const Download = () => {
	const { t } = useTranslation()

	return (
		<>
			<div className={`${styles.download} ${styles.hfull} d-flex justify-content-center align-items-center`}>
				<Container className={styles.download__content}>
					<Row className='d-flex flex-column flex-md-row justify-content-center align-items-center'>
						<Col xs={12} md={6} className='d-flex flex-column justify-content-center align-items-center mt-5'>
							<div>
								<Image src='/img/app_download/findjobs-phone.png' alt='' className={styles.imgPhoneDownload} />
							</div>
						</Col>
						<Col className='d-flex flex-column justify-content-center align-items-center align-items-md-start'>
							<h1 className={`${styles.title} text-white`}>
								{t('download_app_landing_page.broadcast_yourself')}
							</h1>
							<h3 className={`${styles.subtitle} text-white mb-3`}>
								{t('download_app_landing_page.broadcast_yourself_subtitle')}
							</h3>
							<div className='my-2 my-md-5 d-flex flex-column flex-sm-row align-items-center'>
								<div>
									<Image src='/img/app_download/qr.png' alt='qr' className={styles.qr} />
								</div>
								<div className={styles.dividerVertical} />
								<div className='d-flex flex-column'>
									<Link href='https://apps.apple.com/sg/app/findjobs-find-jobs-easily/id1627650264'>
										<a target="_blank">
											<Image src='/img/store/AppStore.png' alt='AppStore' width='100%' className={`my-2 ${styles.store}`} />
										</a>
									</Link>
									<Link href='https://play.google.com/store/apps/details?id=com.findjobs.findjobsapp'>
										<a target="_blank">
											<Image src='/img/store/PlayStore.png' alt='PlayStore' width='100%' className={`my-2 ${styles.store}`} />
										</a>
									</Link>
									<Link href='https://appgallery.huawei.com/app/C106400895'>
										<a target="_blank">
											<Image src='/img/store/AppGallery.png' alt='AppGallery' width='100%' className={`my-2 ${styles.store}`} />
										</a>
									</Link>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
				<Image src='/img/app_download/bg-download.png' alt='' height='100px' className={styles.download__image} />
			</div>
		</>
	)
}

export default Download