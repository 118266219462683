// Bootstrap
import {
	Col,
	Row,
	Image,
	Container
} from 'react-bootstrap'

// Style
import styles from '../../styles/App.module.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// Other
import { useTranslation } from 'react-i18next'

const Cv = () => {
	const { t } = useTranslation()

	return (
		<>
			<Container className='py-1 py-md-5'>
				<div style={{width:'70%'}} className='d-flex flex-column align-item-center justify-content-end mx-auto'>
					<Row className='my-4 my-md-2 d-flex flex-column flex-md-row'>
						<Col xs={12} md={5} className='d-flex flex-column justify-content-center align-items-center'>
							<Image src='/img/app_download/broadcast.png' alt='jobs' className={`${styles.imgDownload} mb-2 mb-md-5`} />
						</Col>
						<Col className='d-flex flex-column justify-content-center align-items-center align-items-lg-start'>
							<h3 className={`${styles.subtitle} my-2`}>
								{t('download_app_landing_page.broadcast_yourself')}
							</h3>
							<p className={styles.text}>
								{t('download_app_landing_page.broadcast_yourself_subtitle')}
							</p>
							<div className={styles.divider} />
							<p>
								{t('download_app_landing_page.broadcast_yourself_description')}
							</p>
						</Col>
					</Row>
					<Row className='my-5 my-md-2 d-flex flex-column-reverse flex-md-row'>
						<Col xs={12} md={8} className='d-flex flex-column justify-content-center align-items-center align-items-lg-start'>
							<h3 className={`${styles.subtitle} my-2`}>{t('download_app_landing_page.scan_jobs')}</h3>
							<p className={styles.text}>{t('download_app_landing_page.scan_jobs_subtitle')}</p>
							<div className={styles.divider} />
							<p>
								{t('download_app_landing_page.scan_jobs_description')}
							</p>
						</Col>
						<Col className='d-flex flex-column justify-content-center align-items-center'>
							<Image src='/img/app_download/scan.png' alt='interview' className={`${styles.imgDownload} mb-2 mb-md-5`} />
						</Col>
					</Row>
					<Row className='my-5 my-md-2 d-flex flex-column flex-md-row'>
						<Col xs={12} md={5} className='d-flex flex-column justify-content-center align-items-center'>
							<Image src='/img/app_download/create.png' alt='jobs' className={`${styles.imgDownload} mb-2 mb-md-5`} />
						</Col>
						<Col className='d-flex flex-column justify-content-center align-items-center align-items-lg-start'>
							<h3 className={`${styles.subtitle} my-2`}>
								{t('download_app_landing_page.create_cv')}
							</h3>
							<p className={styles.text}>
								{t('download_app_landing_page.create_cv_subtitle')}
							</p>
							<div className={styles.divider} />
							<p>
								{t('download_app_landing_page.create_cv_description')}
							</p>
						</Col>
					</Row>
					<br />
					<Row className='my-5 my-md-2 d-flex flex-column-reverse flex-md-row'>
						<Col className='d-flex flex-column justify-content-center align-items-center align-items-lg-start'>
							<h3 className={`${styles.subtitle} my-2`}>
								Walk-In Interview
							</h3>
							<p className={styles.text}>
								Get hired fast
							</p>
							<div className={styles.divider} />
							<p>
								Sign up for our Walk-In Interviews, where you can get hired on the spot if chosen for a job! 
							</p>
						</Col>
						<Col xs={12} md={5} className='d-flex flex-column justify-content-center align-items-center'>
							<Image src='/img/app_download/Walk-In_Interviews.png' alt='jobs' className={`${styles.imgDownload} mb-2 mb-md-5`} />
						</Col>
					</Row>
					<br />
					<Row className='my-5 my-md-2 d-flex flex-column flex-md-row'>
						<Col xs={12} md={5} className='d-flex flex-column justify-content-center align-items-center'>
							<Image src='/img/app_download/Virtual_Career_Fairs.png' alt='jobs' className={`${styles.imgDownload} mb-2 mb-md-5`} />
						</Col>
						<Col className='d-flex flex-column justify-content-center align-items-center align-items-lg-start'>
							<h3 className={`${styles.subtitle} my-2`}>
								Virtual Career Fairs
							</h3>
							<p className={styles.text}>
								Browse various jobs
							</p>
							<div className={styles.divider} />
							<p>
								Our VCFs bring together employers from various industries and offers a huge pool of jobs for you to choose from
							</p>
						</Col>
					</Row>
				</div>
			</Container>
		</>
	)
}

export default Cv