// Next
import type { NextPage } from 'next'

// style
import styles from '../styles/App.module.scss'

// Other
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

// Components
import Hero from '../components/App/Hero'
import Cv from '../components/App/Cv'
import Download from '../components/App/Download'

const Home: NextPage = () => {

	return (
		<>
			<div className={styles.landing}>
				<Hero />
				<Cv />
				<Download />
			</div>
		</>
	)
}

export async function getStaticProps({ locale }) {
	return {
		props: {
			...(await serverSideTranslations(locale, ['common']))
			// Will be passed to the page component as props
		}
	}
}

export default Home
